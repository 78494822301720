// eslint-disable-next-line import/no-extraneous-dependencies
import { decode } from 'js-base64'
export function decodeBase64(input: string | (string | null)[] | null): string {
  if (input && typeof input === 'string') {
    try {
      return decode(input)
    } catch (e) {
      console.error('Failed to decode the Base64 string.', e)
      return ''
    }
  }

  return ''
}
