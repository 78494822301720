import styled from '@emotion/styled'
import {
  TextField,
  Button,
  Checkbox as MuiCheckbox,
  FormControlLabel,
} from '@mui/material'

export const LineBreak = styled.div`
  height: 30px;
  width: 100%;
`

export const FormLabel = styled(FormControlLabel)<{ error?: boolean }>`
  color: ${({ error }) => (error ? 'red' : 'white')};
`

export const Checkbox = styled(MuiCheckbox)<{ error?: boolean }>`
  margin: 0 10px !important;

  svg {
    path {
      fill: ${({ error }) => (error ? 'red' : 'white')};
    }
  }

  .Mui-checked {
    color: white;
  }
`
export const ErrorContainer = styled.div`
  width: 100%;
  margin: 0 15px;
  color: white;
  display: flex;
  flex-direction: column;
`

export const ErrorItem = styled.div`
  display: flex;

  svg path {
    fill: red;
  }
`

export const ErrorMsg = styled.span`
  color: red;
  font-size: 16px;
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
`
export const FormInput = styled(TextField)<{ multiline?: boolean }>`
  background: white;
  width: ${(props) => (props.multiline ? '100%' : '300px')};
  margin: 0 10px !important;
  border-radius: 4px;

  @media screen and (max-width: 990px) {
    width: 100%;
    margin: 10px 0 !important;
  }

  input {
    background: white !important;
  }

  .Mui-error > input {
    border: 1px solid red;
    box-shadow: rgb(255 0 0) 0 0 18px 2px;
  }
`

export const SelectedSlotContainer = styled.div`
  display: flex;
  width: min-content;

  @media screen and (max-width: 990px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`

export const SelectedSlotWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 0 10px !important;
`

export const SelectedSlot = styled.span<{ isSelected?: boolean }>`
  color: ${({ isSelected }) => (isSelected ? 'red' : 'white')};
  text-align: center;
  margin-bottom: 5px;
  font-weight: bold;
  font-style: italic;
  font-size: 16px;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: min-content;

  @media screen and (max-width: 990px) {
    width: 100%;
  }
`

export const InputGroupHolder = styled.div`
  display: flex;
  margin-top: 10px;
  padding: 5px;

  @media screen and (max-width: 990px) {
    flex-direction: column;
  }
`

export const CheckboxHolder = styled.div`
  margin-top: unset;
  padding-left: 5px;
  display: flex;
  flex-direction: column;
`

export const CheckboxTxt = styled.span`
  color: white;
  font-size: 12px;
  margin: 0 10px !important;
`

export const ReservationBtn = styled(Button)`
  color: #fff !important;
  background-color: #337ab7 !important;
  width: fit-content;
  padding: 10px !important;
  margin: 0 10px !important;

  &:hover {
    background-color: #ff8e00 !important;
  }
`

export const ButtonHolder = styled(InputGroupHolder)`
  justify-content: flex-end;

  @media screen and (max-width: 990px) {
    width: 100%;
    align-items: flex-end;
  }
`

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`

export const ModalCloseIconContainer = styled.div`
  display: flex;
  justify-content: end;

  button {
    padding: unset;
  }
`
