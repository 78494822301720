import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { Icon } from '@mui/material'
import React, { Dispatch, SetStateAction, useState, useCallback } from 'react'

import {
  ISlot,
  IPlayers,
  Reservation,
  Schedule,
  Tarif,
  Extended,
  IPrice,
  Blocking,
  ReservationResponse,
} from '@facades/reservation'
import useIsMobile from '@hooks/useIsMobile'
import {
  addTwoWeeks,
  currentDate,
  formatDateToReadableString,
  generateTimeSlots,
  getTarif,
  isStartDateValid,
  isStartDateWithinTwoMonths,
  isWeekend,
  subtractTwoWeeks,
  getPrice,
} from '@utils/reservation'
import { translationUtil } from '@utils/translationUtil'

import {
  Container,
  ReservationDateItem,
  ReservationRowItem,
  ReservationSelectableItem,
  ReservationSelectableRow,
  PaginationButton,
  ReservationSystemGrid,
  ButtonPlaceholder,
  ReservationRowItemTime,
  ReservationItemContainer,
  ReservationItemContainerTimeItem,
} from './reservationGrid.styled'

export function ReservationGrid({
  reservation,
  blocking,
  isAdminAvailable,
  schedules,
  players,
  slot,
  tariffDays,
  setSlot,
}: {
  reservation: Reservation
  blocking: Blocking
  isAdminAvailable: boolean
  schedules: Schedule[]
  players: IPlayers
  slot: ISlot
  tariffDays: ReservationResponse['tariffDays']
  setSlot: Dispatch<SetStateAction<ISlot>>
}) {
  const [startDate, setStartDate] = useState<string>(currentDate)
  const [selected, setSelected] = useState<string>('')
  const translations = translationUtil()
  const timeSlots = generateTimeSlots({
    startDate,
    numDays: 14,
    schedules,
  })

  const handleSelectSlot = useCallback(
    (
      time: string,
      date: string,
      id: number,
      price: IPrice,
      extended: Extended[],
      tarif: Tarif
    ) => {
      setSlot({ date, time, id, price, extended, tarif })
    },
    [players]
  )

  const isMobile = useIsMobile()

  const Slots = ({
    time,
    date,
    id,
    price,
    extended,
  }: {
    time: string[]
    date: string
    id: number
    price: IPrice
    extended: Extended[]
  }) => (
    <ReservationSelectableRow>
      {time.map((item, l) => {
        const tarif = getTarif(
          date,
          item,
          reservation,
          blocking,
          isAdminAvailable,
          tariffDays
        )

        return (
          <ReservationItemContainer key={`${l + 1}`}>
            <ReservationItemContainerTimeItem>
              {item}
            </ReservationItemContainerTimeItem>
            <ReservationSelectableItem
              selected={slot.date === date && slot.time === item}
              onClick={() =>
                tarif !== Tarif.Booked &&
                handleSelectSlot(item, date, id, price, extended, tarif)
              }
              tarif={tarif}
            >
              {price?.[tarif]}
              {tarif !== Tarif.Booked && ' Kč'}
            </ReservationSelectableItem>
          </ReservationItemContainer>
        )
      })}
    </ReservationSelectableRow>
  )

  const RenderSlots = ({
    time,
    date,
    id,
    price,
    extended,
  }: {
    time: string[]
    date: string
    id: number
    price: IPrice
    extended: Extended[]
  }) => {
    if (!isMobile || selected === date || slot.date === date) {
      return (
        <Slots
          time={time}
          date={date}
          id={id}
          price={price}
          extended={extended}
        />
      )
    }
  }

  return (
    <Container>
      {isStartDateValid(startDate) && (
        <PaginationButton
          type="button"
          top
          onClick={() => setStartDate(subtractTwoWeeks(startDate))}
        >
          <Icon>
            <ArrowBackIosIcon />
          </Icon>
          <span className="txt">{translations.previous14Days}</span>
        </PaginationButton>
      )}
      <ReservationSystemGrid>
        {timeSlots.map((slots) => (
          <>
            <ReservationRowItemTime>
              <ReservationDateItem isPlaceholder />
              <ReservationSelectableRow>
                {slots?.[0]?.time.map((item, l) => (
                  <ReservationSelectableItem key={`${l + 1}`}>
                    {item}
                  </ReservationSelectableItem>
                ))}
              </ReservationSelectableRow>
            </ReservationRowItemTime>
            {slots.map(({ time, date, id, extended }, k) => {
              const price: IPrice = getPrice(extended, players.players)

              return (
                <ReservationRowItem key={`${k + 1}`}>
                  <ReservationDateItem
                    selected={slot.date === date}
                    isWeekend={isWeekend(date)}
                    onClick={() => isMobile && setSelected(date)}
                    hoverable={isMobile}
                  >
                    {formatDateToReadableString(date)}
                  </ReservationDateItem>
                  <RenderSlots
                    time={time}
                    date={date}
                    id={id}
                    price={price}
                    extended={extended}
                  />
                </ReservationRowItem>
              )
            })}
          </>
        ))}
      </ReservationSystemGrid>
      {isStartDateWithinTwoMonths(startDate) ? (
        <PaginationButton
          type="button"
          onClick={() => setStartDate(addTwoWeeks(startDate))}
        >
          <span className="txt">{translations.next14Days}</span>
          <Icon>
            <ArrowBackIosIcon />
          </Icon>
        </PaginationButton>
      ) : (
        <ButtonPlaceholder />
      )}
    </Container>
  )
}
