import CircularProgress from '@mui/material/CircularProgress'
import { SelectChangeEvent } from '@mui/material/Select'
import React, { useState } from 'react'

import { ReservationCancel } from '@components/reservationCancel'
import { ReservationForm } from '@components/reservationForm'
import { ReservationGrid } from '@components/reservationGrid'
import { ReservationNumberOfPlayers } from '@components/reservationNumberOfPlayers'
import { ISlot, ReservationResponse, Tarif } from '@facades/reservation'
import { useGetReservations } from '@hooks/useGetReservations'

import { ReservationContainer } from './reservation.styled'

const initialState = {
  date: '',
  time: '',
  price: {
    low: null,
    medium: null,
    high: null,
    booked: '',
  },
  extended: [
    {
      name: '',
      low: '',
      medium: '',
      high: '',
    },
  ],
}

export function ReservationSystem({ data }: { data: ReservationResponse }) {
  const [players, setPlayers] = useState<{ players: string; id?: number }>({
    players: data?.schedules?.[0].extended?.[0]?.name,
  })
  const [slot, setSlot] = useState<ISlot>({
    ...initialState,
    tarif: Tarif.Low,
    id: data?.schedules?.[0]?.quest_book_id,
  })
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setPlayers({
      players: event.target.value as string,
    })
  }

  return (
    <ReservationContainer>
      {data?.schedules.map((item, k) => {
        if (item.quest_book_id === slot.id) {
          return (
            <ReservationNumberOfPlayers
              key={`${1 + k}`}
              items={item.extended}
              players={players}
              handleChange={handleChange}
            />
          )
        }

        return null
      })}
      <ReservationGrid
        schedules={data.schedules}
        reservation={data.reservation}
        blocking={data.blockings}
        isAdminAvailable={data.isAdminAvailable}
        players={players}
        slot={slot}
        tariffDays={data.tariffDays}
        setSlot={setSlot}
      />
      <ReservationForm slot={slot} players={players} token={data.token} />
      <ReservationCancel reservation={data.reservation} meta={data?.meta} />
    </ReservationContainer>
  )
}

export const Reservation = () => {
  const { data } = useGetReservations(window.quest_res_questname)

  if (data && data?.token) {
    return <ReservationSystem data={data} />
  }

  return (
    <ReservationContainer withLoader>
      <CircularProgress color="secondary" />
    </ReservationContainer>
  )
}
