import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import { SelectInputProps } from '@mui/material/Select/SelectInput'
import React from 'react'

import { Extended } from '@facades/reservation'
import { translationUtil } from '@utils/translationUtil'

import { MuiSelect } from './reservationNumberOfPlayers.styled'

export function ReservationNumberOfPlayers({
  players,
  items,
  handleChange,
}: {
  players: { players: string; id?: number }
  items: Extended[]
  handleChange: SelectInputProps['onChange']
}) {
  const translations = translationUtil()

  return (
    <FormControl fullWidth>
      <MuiSelect
        isHighlighted={players.players === 'default'}
        value={players.players}
        onChange={handleChange}
      >
        {items?.map(({ name }, k) => (
          <MenuItem key={`${k + 1}`} value={name}>
            {name === 'default' ? translations.nmbPlayers : name}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  )
}
