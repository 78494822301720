import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const ReservationContainer = styled.div<{ withLoader?: boolean }>`
  padding: 32px;
  max-width: 1200px;
  margin: auto;
  width: 100%;
  overflow-x: hidden;

  ${({ withLoader }) =>
    withLoader &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
    `}
`
