import styled from '@emotion/styled'

export const ReservationCancelStyled = styled.div`
  display: flex;
  flex-direction: column;
`

export const ReservationCancelItem = styled.div`
  display: flex;

  .key {
    min-width: 70px;
  }

  .value {
    font-weight: bold;
    margin-left: 10px;
  }
`

export const BtnContainer = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
`
