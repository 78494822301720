import axios from 'axios'
import { Dispatch, SetStateAction } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import { QueriesEnum } from '@facades/queries'
import { ICancelData } from '@facades/reservation'
import { getEndpoint } from '@utils/getEndpoint'

const cancelReservation = async (data: { data: string }) => {
  const response = await axios.post(
    `${getEndpoint()}/cancel_reservation`,
    data,
    {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    }
  )
  return response.data
}

export const useCancelReservation = (
  setShowModal?: Dispatch<SetStateAction<ICancelData | boolean | number>>
) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(cancelReservation, {
    onSuccess: (res) => {
      if (res.status !== 400) {
        queryClient.invalidateQueries(QueriesEnum.reservations)
        setShowModal?.(false)
      }
    },
    onError: (error: Error) => {
      console.error('Failed to make reservation:', error)
    },
  })

  const { isLoading } = mutation

  return {
    ...mutation,
    isLoading,
  }
}
