import { yupResolver } from '@hookform/resolvers/yup'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import React, { useEffect } from 'react'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import * as Yup from 'yup'

import {
  IFormInputQuestReservation,
  IPlayers,
  IPrice,
  ISlot,
  QuestReservationKeys,
} from '@facades/reservation'
import { useMakeReservation } from '@hooks/useMakeReservation'
import { formatDateToUser, getPrice } from '@utils/reservation'
import { translationUtil } from '@utils/translationUtil'

import {
  FormInput,
  Form,
  InputGroupHolder,
  Container,
  ReservationBtn,
  ButtonHolder,
  SelectedSlotContainer,
  SelectedSlotWrapper,
  SelectedSlot,
  ErrorContainer,
  ErrorItem,
  ErrorMsg,
  Checkbox,
  FormLabel,
  CheckboxHolder,
  LineBreak,
  CheckboxTxt,
  LoaderContainer,
  ModalCloseIconContainer,
} from './reservationForm.styled'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

// Define your Yup schema
const schema = Yup.object().shape({
  [QuestReservationKeys.QUEST_RES_USERNAME]: Yup.string().required(
    translationUtil().reservationFormNameValidation
  ),
  [QuestReservationKeys.QUEST_RES_USERPHONE]: Yup.string()
    .required(translationUtil().reservationFormPhoneValidation)
    .matches(/^\+?[0-9]+$/, translationUtil().reservationFormPhoneValidationTwo)
    .min(8, translationUtil().reservationFormPhoneValidationThree),
  [QuestReservationKeys.QUEST_RES_USEREMAIL]: Yup.string()
    .required(translationUtil().reservationFormEmailValidation)
    .email(translationUtil().reservationFormEmailValidationTwo),
  [QuestReservationKeys.QUEST_RES_COMING_DATE]: Yup.string().required(
    translationUtil().reservationFormDateValidation
  ),
  [QuestReservationKeys.QUEST_RES_COMING_TIME]: Yup.string().required(
    translationUtil().reservationFormTimeValidation
  ),
  [QuestReservationKeys.QUEST_RES_PACKAGE]: Yup.string().required(
    translationUtil().reservationFormNmbPplValidation
  ),
  [QuestReservationKeys.QUEST_RES_PRICE]: Yup.string().required(
    translationUtil().reservationFormPriceValidation
  ),
  [QuestReservationKeys.QUEST_RES_USER_NOTES]: Yup.string(),
  [QuestReservationKeys.QUEST_RES_VAUCHER_ID]: Yup.string(),
  [QuestReservationKeys.QUEST_RES_RULES_AND_CONDS]: Yup.boolean().oneOf(
    [true],
    translationUtil().reservationFormConditionsValidation
  ),
  [QuestReservationKeys.QUEST_RES_GDPR]: Yup.boolean().oneOf(
    [true],
    translationUtil().reservationFormGDPRValidation
  ),
})

// eslint-disable-next-line react/function-component-definition
export const ReservationForm: React.FC<{
  slot: ISlot
  players: IPlayers
  token: string
}> = ({ slot, players, token }) => {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<Partial<IFormInputQuestReservation>>({
    resolver: yupResolver(schema) as any,
  })
  const { mutate, isLoading, setShowExistsModal, showExistsModal } =
    useMakeReservation()
  const translations = translationUtil()
  const price: IPrice = getPrice(slot.extended, players.players)

  useEffect(() => {
    if (slot.date) {
      setValue(QuestReservationKeys.QUEST_RES_COMING_DATE, slot.date, {
        shouldValidate: true,
      })
    }
    if (slot.time) {
      setValue(QuestReservationKeys.QUEST_RES_COMING_TIME, `${slot.time}:00`, {
        shouldValidate: true,
      })
    }
    if (slot.time) {
      setValue(QuestReservationKeys.QUEST_RES_COMING_TIME, `${slot.time}:00`, {
        shouldValidate: true,
      })
    }
    if (price?.[slot?.tarif]) {
      setValue(
        QuestReservationKeys.QUEST_RES_PRICE,
        price[slot.tarif] as unknown as number,
        {
          shouldValidate: true,
        }
      )
    }
    if (players.players) {
      setValue(
        QuestReservationKeys.QUEST_RES_PACKAGE,
        players.players !== 'default'
          ? (players.players as unknown as number)
          : undefined,
        {
          shouldValidate: true,
        }
      )
    }
  }, [slot, players, price?.[slot?.tarif]])

  const errorNotification = [
    {
      msg: errors[QuestReservationKeys.QUEST_RES_USERNAME]?.message,
    },
    {
      msg: errors[QuestReservationKeys.QUEST_RES_USERPHONE]?.message,
    },
    {
      msg: errors[QuestReservationKeys.QUEST_RES_USEREMAIL]?.message,
    },
    {
      msg: errors[QuestReservationKeys.QUEST_RES_COMING_DATE]?.message,
    },
    {
      msg: errors[QuestReservationKeys.QUEST_RES_COMING_TIME]?.message,
    },
    {
      msg: errors[QuestReservationKeys.QUEST_RES_PACKAGE]?.message,
    },
    {
      msg: errors[QuestReservationKeys.QUEST_RES_PRICE]?.message,
    },
    {
      msg: errors[QuestReservationKeys.QUEST_RES_RULES_AND_CONDS]?.message,
    },
    {
      msg: errors[QuestReservationKeys.QUEST_RES_GDPR]?.message,
    },
  ].filter(Boolean)

  const onSubmit: SubmitHandler<Partial<IFormInputQuestReservation>> = async (
    data
  ) => {
    mutate({
      ...data,
      quest_res_questname: window.quest_res_questname,
      quest_res_post_id: window.quest_res_post_id,
      quest_res_locale: window.wpmlLanguage,
      quest_reservation_token: token,
    } as IFormInputQuestReservation)
  }

  return (
    <Container>
      <SelectedSlotContainer>
        <SelectedSlotWrapper>
          <SelectedSlot>
            {translations.reservationFormChooseGameDate}
          </SelectedSlot>
          {!!slot.date && (
            <SelectedSlot isSelected>
              {formatDateToUser(slot.date)}
            </SelectedSlot>
          )}
        </SelectedSlotWrapper>
        <SelectedSlotWrapper>
          <SelectedSlot>
            {translations.reservationFormTimeOfTheGame}
          </SelectedSlot>
          {!!slot.time && <SelectedSlot isSelected>{slot.time}</SelectedSlot>}
        </SelectedSlotWrapper>
        <SelectedSlotWrapper>
          <SelectedSlot>{translations.reservationFormPrice}</SelectedSlot>
          {!!price?.[slot?.tarif] && (
            <SelectedSlot isSelected>{`${price?.[
              slot?.tarif
            ]} Kč`}</SelectedSlot>
          )}
        </SelectedSlotWrapper>
      </SelectedSlotContainer>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name={QuestReservationKeys.QUEST_RES_COMING_DATE}
          control={control}
          render={({ field }) => <input type="hidden" {...field} />}
        />
        <Controller
          name={QuestReservationKeys.QUEST_RES_COMING_TIME}
          control={control}
          render={({ field }) => <input type="hidden" {...field} />}
        />
        <Controller
          name={QuestReservationKeys.QUEST_RES_PACKAGE}
          control={control}
          render={({ field }) => <input type="hidden" {...field} />}
        />
        <Controller
          name={QuestReservationKeys.QUEST_RES_PRICE}
          control={control}
          render={({ field }) => <input type="hidden" {...field} />}
        />
        <InputGroupHolder>
          <Controller
            name={QuestReservationKeys.QUEST_RES_USERNAME}
            control={control}
            rules={{ required: translations.reservationFormNameValidation }}
            render={({ field: { onChange, onBlur, value } }) => (
              <FormInput
                label={translations.reservationFormNameField}
                error={!!errors[QuestReservationKeys.QUEST_RES_USERNAME]}
                onChange={onChange}
                variant="filled"
                onBlur={onBlur}
                value={value}
              />
            )}
          />
          <Controller
            name={QuestReservationKeys.QUEST_RES_USERPHONE}
            control={control}
            rules={{ required: translations.reservationFormPhoneValidation }}
            render={({ field: { onChange, onBlur, value } }) => (
              <FormInput
                label={translations.reservationFormPhoneField}
                error={!!errors[QuestReservationKeys.QUEST_RES_USERPHONE]}
                onChange={onChange}
                variant="filled"
                onBlur={onBlur}
                value={value}
              />
            )}
          />

          <Controller
            name={QuestReservationKeys.QUEST_RES_USEREMAIL}
            control={control}
            rules={{ required: translations.reservationFormEmailValidation }}
            render={({ field: { onChange, onBlur, value } }) => (
              <FormInput
                label={translations.reservationFormEmailField}
                error={!!errors[QuestReservationKeys.QUEST_RES_USEREMAIL]}
                onChange={onChange}
                variant="filled"
                onBlur={onBlur}
                value={value}
              />
            )}
          />
        </InputGroupHolder>
        <InputGroupHolder>
          <Controller
            name={QuestReservationKeys.QUEST_RES_USER_NOTES}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <FormInput
                label={translations.reservationFormMessageField}
                multiline
                rows={4}
                onChange={onChange}
                variant="filled"
                onBlur={onBlur}
                value={value}
              />
            )}
          />
        </InputGroupHolder>
        <InputGroupHolder>
          <Controller
            name={QuestReservationKeys.QUEST_RES_VAUCHER_ID}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <FormInput
                label={translations.reservationFormVaucherField}
                onChange={onChange}
                variant="filled"
                onBlur={onBlur}
                value={value}
              />
            )}
          />
        </InputGroupHolder>
        <LineBreak />
        <CheckboxHolder>
          <Controller
            name={QuestReservationKeys.QUEST_RES_RULES_AND_CONDS}
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <FormLabel
                error={!!errors[QuestReservationKeys.QUEST_RES_RULES_AND_CONDS]}
                control={
                  <Checkbox
                    checked={field.value}
                    error={
                      !!errors[QuestReservationKeys.QUEST_RES_RULES_AND_CONDS]
                    }
                    onChange={(e) => field.onChange(e.target.checked)}
                    name={QuestReservationKeys.QUEST_RES_RULES_AND_CONDS}
                  />
                }
                label={translations.reservationFormGameRules}
              />
            )}
          />
          {watch(QuestReservationKeys.QUEST_RES_RULES_AND_CONDS) && (
            <CheckboxTxt>
              {translations.reservationFormGameRulesConsent}
            </CheckboxTxt>
          )}
        </CheckboxHolder>
        <CheckboxHolder>
          <Controller
            name={QuestReservationKeys.QUEST_RES_GDPR}
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <FormLabel
                error={!!errors[QuestReservationKeys.QUEST_RES_GDPR]}
                control={
                  <Checkbox
                    checked={field.value}
                    error={!!errors[QuestReservationKeys.QUEST_RES_GDPR]}
                    onChange={(e) => field.onChange(e.target.checked)}
                    name={QuestReservationKeys.QUEST_RES_GDPR}
                  />
                }
                label={translations.reservationFormGameGDPR}
              />
            )}
          />
          {watch(QuestReservationKeys.QUEST_RES_GDPR) && (
            <CheckboxTxt>{translations.reservationFormGameConsent}</CheckboxTxt>
          )}
        </CheckboxHolder>
        <ButtonHolder>
          <ReservationBtn type="submit" variant="contained" color="primary">
            {translations.reservationFormBookGameBtn}
          </ReservationBtn>
        </ButtonHolder>
        <ErrorContainer>
          {errorNotification.map(
            ({ msg }, k) =>
              !!msg && (
                <ErrorItem key={`${1 + k}`}>
                  <ErrorMsg>* {msg}</ErrorMsg>
                </ErrorItem>
              )
          )}
        </ErrorContainer>
      </Form>
      <Modal
        open={isLoading}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {translations.processingYourReservation}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {translations.reservationFormReservationProcessing}
          </Typography>
          <LoaderContainer>
            <CircularProgress color="secondary" />
          </LoaderContainer>
        </Box>
      </Modal>
      <Modal
        open={!isLoading && showExistsModal}
        onClose={() => setShowExistsModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ModalCloseIconContainer>
            <IconButton onClick={() => setShowExistsModal(false)}>
              <CloseIcon />
            </IconButton>
          </ModalCloseIconContainer>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {translations.reservationFormAlreadyExists}
          </Typography>
        </Box>
      </Modal>
    </Container>
  )
}
