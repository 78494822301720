import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Select from '@mui/material/Select'
import { SelectInputProps } from '@mui/material/Select/SelectInput'

export const MuiSelect = styled(Select)<{
  onChange?: SelectInputProps['onChange']
  isHighlighted: boolean
}>`
  background: white;
  max-width: 300px;

  ${(props) =>
    props.isHighlighted &&
    css`
      border: 1px solid red;
      box-shadow: rgb(255 0 0) 0 0 18px 2px;
    `}
`
