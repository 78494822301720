export type GameNames =
  | 'starelement'
  | 'zombie'
  | 'vabank'
  | 'magic'
  | 'flymotion'
  | 'moriarty'
  | 'magic2'
  | 'magic_cup'
  | 'bomb'
  | 'mission_on_mars'
  | 'magic_cup_rental'
  | 'bomb_rental'
  | 'mission_on_mars_rental'
  | 'jury'
  | 'stardomination'
  | 'questquiz'
  | 'snowcastle'
export type Locales = 'cz' | 'en' | 'ru' | 'cs'

export function getGameName(
  gameName: GameNames,
  locale: Locales
): string | undefined | null {
  const gameTranslations: {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    [key in GameNames]: { [key in Locales]?: string }
  } = {
    starelement: {
      cz: 'HVÉZDNÝ ELEMENT',
      cs: 'HVÉZDNÝ ELEMENT',
      en: 'THE STAR ELEMENT',
      ru: 'ЗВЕЗДНЫЙ ЭЛЕМЕНТ',
    },
    zombie: {
      cz: 'APOCALYPSE ZOMBIE 2213',
      cs: 'APOCALYPSE ZOMBIE 2213',
      en: 'APOCALYPSE ZOMBIE 2213',
      ru: 'ЗОМБИ-АПОЛАЛИПСИС 2213',
    },
    vabank: {
      cz: 'VA-BANK',
      cs: 'VA-BANK',
      en: 'VA-BANK',
      ru: 'КВЕСТ ВА-БАНК',
    },
    magic: {
      cz: 'ÚNIK Z ČARODĚJNICKÉ ŠKOLY MAGIE A KOUZEL',
      cs: 'ÚNIK Z ČARODĚJNICKÉ ŠKOLY MAGIE A KOUZEL',
      en: 'ESCAPE FROM MAGIC ROOM',
      ru: 'ВОЛШЕБНАЯ КОМНАТА ГАРРИ',
    },
    flymotion: {
      cz: 'FLY MOTION ATRAKCE LETECKÉ BITVY',
      cs: 'FLY MOTION ATRAKCE LETECKÉ BITVY',
      en: 'FLY MOTION ATTRACTION OF AIR COMBAT',
      ru: 'FLY MOTION АТТРАКЦИОН ВОЗДУШНЫХ БОЕВ',
    },
    moriarty: {
      cz: 'NÁSTRAHY MORIARTYHO FANTOMA',
      cs: 'NÁSTRAHY MORIARTYHO FANTOMA',
      en: "MORIARTY'S PHANTOM TRAP",
      ru: 'ЛОВУШКА ПРИЗРАКА МОРИАРТИ',
    },
    magic2: {
      cz: 'ČARODĚJNICKÁ ŠKOLA MAGIE A KOUZEL - EPIZODA II',
      cs: 'ČARODĚJNICKÁ ŠKOLA MAGIE A KOUZEL - EPIZODA II',
      en: 'MAGIC ESCAPE ROOM II - TRY THE TRUE MAGIC',
      ru: 'ШКОЛА МАГИИ ЭПИЗОД II',
    },
    magic_cup: {
      cz: 'ÚNIKOVÝ BOX - MAGICKÝ POHÁR',
      cs: 'ÚNIKOVÝ BOX - MAGICKÝ POHÁR',
      en: 'MAGIC ESCAPE ROOM BOX',
      ru: 'ШКОЛА МАГИИ ПОРТАТИВНЫЙ',
    },
    bomb: {
      cz: 'ÚNIKOVÝ BOX - BOMBA',
      cs: 'ÚNIKOVÝ BOX - BOMBA',
      en: 'ESCAPE BOX - THE BOMB',
      ru: 'ÚNIKOVÝ BOX - BOMBA',
    },
    mission_on_mars: {
      cz: 'ÚNIKOVÝ BOX - MISE NA MARS',
      cs: 'ÚNIKOVÝ BOX - MISE NA MARS',
      en: 'ESCAPE BOX - MARS MISSION',
      ru: 'ÚNIKOVÝ BOX - MISE NA MARS',
    },
    magic_cup_rental: {
      cz: 'PRONÁJEM ÚNIKOVÝ BOX - MAGICKÝ POHÁR',
      cs: 'PRONÁJEM ÚNIKOVÝ BOX - MAGICKÝ POHÁR',
      en: 'RENTAL MAGIC ESCAPE ROOM BOX',
      ru: 'ШКОЛА МАГИИ ПОРТАТИВНЫЙ',
    },
    bomb_rental: {
      cz: 'PRONÁJEM ÚNIKOVÝ BOX - BOMBA',
      cs: 'PRONÁJEM ÚNIKOVÝ BOX - BOMBA',
      en: 'RENTAL ESCAPE BOX - THE BOMB',
      ru: 'ÚNIKOVÝ BOX - BOMBA',
    },
    mission_on_mars_rental: {
      cz: 'PRONÁJEM ÚNIKOVÝ BOX - MISE NA MARS',
      cs: 'PRONÁJEM ÚNIKOVÝ BOX - MISE NA MARS',
      en: 'RENTAL ESCAPE BOX - MARS MISSION',
      ru: 'ÚNIKOVÝ BOX - MISE NA MARS',
    },
    jury: {
      cz: 'ESCAPE ZOOM POROTA',
      cs: 'ESCAPE ZOOM POROTA',
      en: 'ESCAPE ZOOM - JURY',
      ru: 'РОЛЕВАЯ ИГРА ПРИСЯЖНЫЕ',
    },
    stardomination: {
      cz: 'ONLINE GAME GALAKTICKÁ NADVLÁDA',
      cs: 'ONLINE GAME GALAKTICKÁ NADVLÁDA',
      en: 'ONLINE GAME GALACTIC DOMINATION',
      ru: 'ONLINE Игра ГАЛАКТИЧЕСКОЕ ПРЕВОСХОДСТВО',
    },
    questquiz: {
      cz: 'ESCAPE ZOOM - QUESTQUIZ',
      cs: 'ESCAPE ZOOM - QUESTQUIZ',
      en: 'ESCAPE ZOOM - QUESTQUIZ',
      ru: 'ESCAPE ZOOM - QUESTQUIZ',
    },
    snowcastle: {
      cz: 'ESCAPE ZOOM - SNĚHOVÝ HRAD',
      cs: 'ESCAPE ZOOM - SNĚHOVÝ HRAD',
      en: 'ESCAPE ZOOM - SNOW FORTRESS',
      ru: 'Снежная Крепость',
    },
  }

  if (!gameTranslations[gameName] || !gameTranslations[gameName][locale]) {
    return null
  }

  return gameTranslations[gameName][locale]
}
