import axios from 'axios'
import moment from 'moment-timezone'
import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import { QueriesEnum } from '@facades/queries'
import {
  IFormInputQuestReservation,
  QuestReservationKeys,
} from '@facades/reservation'
import { getEndpoint } from '@utils/getEndpoint'
import { GameNames, getGameName, Locales } from '@utils/getGameName'
import { urlify } from '@utils/urlifyObj'

const makeReservation = async (data: IFormInputQuestReservation) => {
  const response = await axios.post(
    `${getEndpoint()}/create_reservation`,
    { ...data, requestUrl: window.location.href },
    {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    }
  )
  return response.data
}

export const useMakeReservation = () => {
  const [showExistsModal, setShowExistsModal] = useState<boolean>(false)
  const queryClient = useQueryClient()

  const mutation = useMutation(makeReservation, {
    onSuccess: (response, variables) => {
      if (response.redirect) {
        window.location.href = `${window.successfullReservation}?${urlify({
          ...variables,
          [QuestReservationKeys.QUEST_RES_COMING_TIME]: moment(
            variables[QuestReservationKeys.QUEST_RES_COMING_TIME],
            'HH:mm:ss'
          )
            .tz('Europe/Berlin')
            .format('HH:mm'),
          [QuestReservationKeys.QUEST_RES_COMING_DATE]: moment(
            variables[QuestReservationKeys.QUEST_RES_COMING_DATE],
            'YYYY-MM-DD'
          )
            .tz('Europe/Berlin')
            .format('MMMM DD, YYYY'),
          [QuestReservationKeys.QUEST_RES_QUESTNAME]: getGameName(
            variables[QuestReservationKeys.QUEST_RES_QUESTNAME] as GameNames,
            variables[QuestReservationKeys.QUEST_RES_LOCALE] as Locales
          ),
        })}`
        return
      }

      setShowExistsModal(true)
      queryClient.invalidateQueries(QueriesEnum.reservations)
    },
    onError: (error: Error) => {
      console.error('Failed to make reservation:', error)
    },
  })

  const { isLoading } = mutation

  return {
    ...mutation,
    showExistsModal,
    setShowExistsModal,
    isLoading,
  }
}
