export interface Extended {
  name: string
  low: string
  medium: string
  high: string
}

export interface Schedule {
  _id: string
  quest_book_id: number
  quest_book_questname: string
  quest_book_weekdays: string
  quest_book_weekends: string
  quest_book_game_duaration: string
  quest_book_min_pr_couple: number
  quest_book_max_pr_couple: number
  quest_book_min_pr: number
  quest_book_max_pr: number
  quest_book_book_comment: null | string
  quest_book_quest_date: string
  quest_book_nmb_sess_workday: number
  quest_book_nmb_sess_weekend: number
  quest_book_mid_pr_couple: number
  quest_book_mid_pr: number
  extended: Extended[]
}

export interface ReservationRequest {
  quest_res_coming_date: string
  quest_res_coming_time: string
  quest_res_package: string
  quest_res_price: string
  quest_res_username: string
  quest_res_userphone: string
  quest_res_useremail: string
  quest_res_user_notes: string
  quest_res_vaucher_id: string
  quest_res_rules_and_conds: boolean
  quest_res_gdpr: boolean
  quest_res_questname: string
  quest_res_post_id: number
  quest_res_locale: string
  quest_reservation_token: string
}

export interface ReservationTime {
  [time: string]: boolean
}

export interface BlockingTime {
  [time: string]: boolean
}

export interface Reservation {
  [date: string]: ReservationTime
}

export interface Blocking {
  [date: string]: BlockingTime
}

export interface ReservationResponse {
  token: string
  isAdminAvailable: boolean
  schedules: Schedule[]
  blockings: Blocking
  reservation: Reservation
  tariffDays: { [key in Tarif]: Array<string> }
  meta?: { [key: string]: string }
}

export interface TimeSlot {
  date: string
  time: string[]
  id: number
  extended: Extended[]
}

export enum Tarif {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
  Booked = 'booked',
}

export interface IPrice {
  low: string | null
  medium: string | null
  high: string | null
  booked: string
}

export enum QuestReservationKeys {
  QUEST_RES_COMING_DATE = 'quest_res_coming_date',
  QUEST_RES_QUESTNAME = 'quest_res_questname',
  QUEST_RES_USERPHONE = 'quest_res_userphone',
  QUEST_RES_USERNAME = 'quest_res_username',
  QUEST_RES_USEREMAIL = 'quest_res_useremail',
  QUEST_RES_COMING_TIME = 'quest_res_coming_time',
  QUEST_RES_POST_ID = 'quest_res_post_id',
  QUEST_RES_PACKAGE = 'quest_res_package',
  QUEST_RES_PRICE = 'quest_res_price',
  QUEST_RES_LOCALE = 'quest_res_locale',
  QUEST_RES_GDPR = 'quest_res_gdpr',
  QUEST_RES_RULES_AND_CONDS = 'quest_res_rules_and_conds',
  QUEST_RESERVATION_TOKEN = 'quest_reservation_token',
  QUEST_RES_USER_NOTES = 'quest_res_user_notes',
  QUEST_RES_VAUCHER_ID = 'quest_res_vaucher_id',
}

export interface IFormInputQuestReservation {
  [QuestReservationKeys.QUEST_RES_COMING_DATE]: string
  [QuestReservationKeys.QUEST_RES_QUESTNAME]: string
  [QuestReservationKeys.QUEST_RES_USERPHONE]: string
  [QuestReservationKeys.QUEST_RES_USERNAME]: string
  [QuestReservationKeys.QUEST_RES_USEREMAIL]: string
  [QuestReservationKeys.QUEST_RES_COMING_TIME]: string
  [QuestReservationKeys.QUEST_RES_POST_ID]: number
  [QuestReservationKeys.QUEST_RES_PACKAGE]: number
  [QuestReservationKeys.QUEST_RES_PRICE]: number
  [QuestReservationKeys.QUEST_RES_LOCALE]: string
  [QuestReservationKeys.QUEST_RES_GDPR]: boolean
  [QuestReservationKeys.QUEST_RES_RULES_AND_CONDS]: boolean
  [QuestReservationKeys.QUEST_RESERVATION_TOKEN]: string
  [QuestReservationKeys.QUEST_RES_USER_NOTES]: string
  [QuestReservationKeys.QUEST_RES_VAUCHER_ID]: string
}

export interface ISlot {
  date: string
  time: string
  id: number
  price: IPrice
  extended: Extended[]
  tarif: Tarif
}

export interface IPlayers {
  players: string
  id?: number
}

export type ICancelData = {
  sql: number
  game: string
  date: string
  time: string
  name: string
}
