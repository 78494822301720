import axios, { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'

import { QueriesEnum } from '@facades/queries'
import { ReservationResponse } from '@facades/reservation'
import { getEndpoint } from '@utils/getEndpoint'

const fetchReservations = async (
  questname: string
): Promise<ReservationResponse> => {
  const response = await axios.post<ReservationResponse>(
    `${getEndpoint()}/get_reservations`,
    {
      quest_res_questname: questname,
    }
  )
  return response.data
}

export const useGetReservations = (
  questname: string,
  options?: UseQueryOptions<ReservationResponse, AxiosError>
) => {
  return useQuery<ReservationResponse, AxiosError>(
    [QueriesEnum.reservations, questname],
    () => fetchReservations(questname),
    options
  )
}
