import { Button } from '@mui/material'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import { ICancelData, Reservation } from '@facades/reservation'
import { useCancelReservation } from '@hooks/useCancelationReservation'
import { containsCorruptedData } from '@utils/containsCorruptedData'
import { decodeBase64 } from '@utils/decodeBase64'
import { GameNames, getGameName, Locales } from '@utils/getGameName'
import { isBase64 } from '@utils/isBase64'
import { strongDecode } from '@utils/strongDecode'
import { translationUtil } from '@utils/translationUtil'

import {
  ReservationCancelStyled,
  ReservationCancelItem,
  BtnContainer,
} from './reservationCancel.styled'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}
export const ReservationCancel = ({
  reservation,
  meta,
}: {
  reservation: Reservation
  meta?: { [key: string]: string }
}) => {
  const [showModal, setShowModal] = useState<ICancelData | boolean | number>(
    false
  )
  const { isLoading, mutate } = useCancelReservation(setShowModal)
  const translations = translationUtil()
  const cancelData = location.href.split('?cancelReservation=')?.[1]

  useEffect(() => {
    if (cancelData) {
      if (isBase64(cancelData)) {
        const parsed = JSON.parse(decodeBase64(cancelData))
        if (reservation?.[parsed?.date]?.[parsed?.time]) {
          setShowModal(parsed)
        }

        // TODO: remove this block once we stop supporting old cancelation formatting
        if (typeof parsed === 'number' && meta?.[parsed]) {
          const values = strongDecode(meta?.[parsed], String(parsed)).split(
            '#-#'
          )

          setShowModal({
            sql: parsed,
            game: values[2],
            date: values[0],
            time: values[1],
            name: values[3],
          })
        }
      } else if (
        containsCorruptedData(
          decodeBase64(cancelData)?.split('name')?.[1] ?? ''
        )
      ) {
        // TODO: remove this hacky solution once we will have all names properly parsed
        const id = parseInt(
          decodeBase64(cancelData).split(',')[0].split(':')[1]
        )
        if (meta?.[id]) {
          setShowModal({
            sql: id,
            game: decodeBase64(cancelData)
              .split(',')[3]
              .split(':')[1]
              .replace(/"/g, ''),
            date: decodeBase64(cancelData).split(',')[1].split(':')[1],
            time: decodeBase64(cancelData)
              .split(',')[2]
              .split(':"')[1]
              .replace(/"/g, ''),
            name: translations.dearClient,
          })
        }
      }
    }
  }, [cancelData, reservation])

  return (
    <Modal
      open={Boolean(showModal)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {translations.cancelModalTitle}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {translations.cancelModalSubTitle}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <ReservationCancelStyled>
            <ReservationCancelItem>
              <span className="key">{translations.cancelModalName}:</span>
              <span className="value">{(showModal as ICancelData).name}</span>
            </ReservationCancelItem>
            <ReservationCancelItem>
              <span className="key">{translations.cancelModalDate}:</span>
              <span className="value">
                {moment((showModal as ICancelData).date).format('DD MMM, YYYY')}
              </span>
            </ReservationCancelItem>
            <ReservationCancelItem>
              <span className="key">{translations.cancelModalTime}:</span>
              <span className="value">
                {moment((showModal as ICancelData).time, 'HH:mm:ss').format(
                  'HH:mm'
                )}
              </span>
            </ReservationCancelItem>
            <ReservationCancelItem>
              <span className="key">{translations.cancelModalQuest}:</span>
              <span className="value">
                {getGameName(
                  (showModal as ICancelData).game as GameNames,
                  window?.wpmlLanguage as Locales
                )}
              </span>
            </ReservationCancelItem>
          </ReservationCancelStyled>
        </Typography>
        <BtnContainer>
          <Button onClick={() => setShowModal(false)} color="primary">
            {translations.cancelModalCancelBtn}
          </Button>
          <Button
            onClick={() => mutate({ data: cancelData as string })}
            color="warning"
          >
            {isLoading ? 'Loading...' : translations.cancelModalOKBtn}
          </Button>
        </BtnContainer>
      </Box>
    </Modal>
  )
}
