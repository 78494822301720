import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Tarif } from '@facades/reservation'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ReservationRowItem = styled.div`
  display: flex;

  @media screen and (max-width: 990px) {
    flex-direction: column;
  }
`

export const ReservationRowItemTime = styled(ReservationRowItem)`
  @media screen and (max-width: 990px) {
    display: none;
  }
`

export const ReservationSystemGrid = styled.div`
  display: flex;
  flex-direction: column;
  width: min-content;

  @media screen and (max-width: 990px) {
    width: 100%;
  }
`

export const ReservationItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ReservationItemContainerTimeItem = styled.span`
  display: none;
  color: white;

  @media screen and (max-width: 990px) {
    display: block;
  }
`

export const ReservationDateItem = styled.span<{
  isWeekend?: boolean
  selected?: boolean
  isPlaceholder?: boolean
  hoverable?: boolean
}>`
  padding: 8px;
  width: 100%;
  min-width: 200px;
  max-width: 200px;
  margin-bottom: 2px;
  margin-top: 2px;
  margin-right: 10px;
  background: ${(props) => (props.isWeekend ? '#353c4b' : '#202327')};
  text-align: center;
  color: #b7c1d3;
  font-size: 14px;
  user-select: none;

  @media screen and (max-width: 990px) {
    width: 100%;
    max-width: unset;
    margin-bottom: 10px;
  }

  ${(props) =>
    props.selected &&
    css`
      background: red;
      color: white;
    `}

  ${(props) =>
    props.isPlaceholder &&
    css`
      background: transparent;
    `}

  ${(props) =>
    props.hoverable &&
    css`
      cursor: pointer;
    `}
`
export const ReservationSelectableRow = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 990px) {
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }
`

const ItemColor = {
  low: '#3eafdb',
  medium: '#8ec051',
  high: '#533bff',
  booked: '#24262a',
}

export const ReservationSelectableItem = styled.span<{
  tarif?: Tarif
  selected?: boolean
}>`
  display: flex;
  background: ${(props) => props.tarif && ItemColor[props.tarif]};
  padding: 2px;
  border-radius: 4px;
  min-width: 70px;
  width: 70px;
  margin-right: 3px;
  margin-left: 3px;
  height: min-content;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  color: ${(props) => (props.tarif !== Tarif.Booked ? '#fff' : '#343a48')};
  user-select: none;

  ${(props) =>
    props.selected &&
    css`
      background: red;
      color: white;
    `}
`

export const PaginationButton = styled.button<{ top?: boolean }>`
  background: unset;
  width: fit-content;
  border: unset;
  cursor: pointer;
  color: white;
  font-size: 18px;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;

  svg {
    transform: ${(props) => (props.top ? 'rotate(90deg)' : 'rotate(270deg)')};
  }

  &:hover {
    color: #ff8e00;
  }
`

export const ButtonPlaceholder = styled.div`
  height: 105px;
`
